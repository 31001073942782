/*==========================================================================

	Template Elements Stylesheet
	____________________________________________________________

	Date:     17/09/2018
	Author:   Edge Marketing Solutions
	Version:  5.1

=============================================================================*/
/* [Global Variables] - http://sass-lang.com/guide */
/*===========================================================================*/
/*                        [Font Settings]                                  */
/*===========================================================================*/
@import url("https://fonts.googleapis.com/css?family=Quicksand:300,400,700");
/*===========================================================================*/
/*                        [Colour Settings]                                  */
/*===========================================================================*/
/*===========================================================================*/
/*                    [Navigation Settings]                                  */
/*===========================================================================*/
/*===========================================================================*/
/*                  [Feature Navigation Settings]                            */
/*===========================================================================*/
/*===========================================================================*/
/*                      [Download Settings]                                  */
/*===========================================================================*/
/*===========================================================================*/
/*                        [Footer Settings]                                  */
/*===========================================================================*/
/*=========================================================================*/
/* [Global Template Styles] */
body {
  color: #6d6e71;
  font-family: "Quicksand", Arial, sans-serif;
  font-weight: 400;
  font-size: 16px;
}

h1 {
  margin: 0 0 20px 0;
  color: #276FA5;
  font-size: 40px;
  font-weight: 700;
}

h2 {
  margin: 20px 0;
  color: #276FA5;
  font-size: 30px;
  font-weight: 700;
}

h3 {
  margin: 20px 0;
  color: #276FA5;
  font-size: 22px;
  font-weight: 700;
}

h4 {
  color: #6d6e71;
  font-size: 18px;
  font-weight: 700;
}

h5 {
  font-weight: 700;
  text-transform: uppercase;
  font-size: 13px;
  font-weight: 700;
}

p {
  margin-bottom: 15px;
  line-height: 1.6em;
}

p.lead {
  color: #81ba4f;
  font-size: 24px;
  font-weight: 400;
}

.row-spaced {
  margin-bottom: 30px;
}

a {
  -webkit-transition: all 150ms ease-in-out;
  -moz-transition: all 150ms ease-in-out;
  -ms-transition: all 150ms ease-in-out;
  -o-transition: all 150ms ease-in-out;
  transition: all 150ms ease-in-out;
  color: #276FA5;
}

a:hover,
a:focus,
a:active {
  text-decoration: none;
  color: #81ba4f;
}

img {
  max-width: 100%;
}

img.svg-responsive {
  width: 100% \9;
}

.btn.btn-primary {
  background-color: #276FA5 !important;
  border-color: #276FA5 !important;
  color: white;
}

.btn.btn-primary:not(:disabled):not(.disabled):active {
  background-color: #1d537c !important;
  border-color: #1d537c !important;
  color: white;
}

.btn.btn-primary:hover {
  background-color: white !important;
  color: black !important;
  border-color: black !important;
}

.btn.btn-primary:focus, .btn.btn-primary.focus, .btn.btn-primary:active {
  box-shadow: 0 0 0 0.2rem rgba(39, 111, 165, 0.5) !important;
}

/*OPTIONAL FULL WIDTH NAVIGATION - Add nav-justified to <ul class="nav navbar-nav">*/
.navbar-nav.nav-justified > li {
  float: none !important;
}

/* Generated by Glyphter (http://www.glyphter.com) on  Tue Sep 06 2016*/
@font-face {
  font-family: 'social icons';
  src: url("../fonts/social-icons.eot");
  src: url("../fonts/social-icons.eot?#iefix") format("embedded-opentype"), url("../fonts/social-icons.woff") format("woff"), url("../fonts/social-icons.ttf") format("truetype"), url("../fonts/social-icons.svg#social-icons") format("svg");
  font-weight: normal;
  font-style: normal;
}

[class^="social-icon-"]::before, [class*=" social-icon-"]::before {
  display: inline-block;
  font-family: 'social icons';
  font-style: normal;
  font-weight: normal;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.social-icon-square-facebook::before {
  content: '\0041';
}

.social-icon-square-google-plus::before {
  content: '\0042';
}

.social-icon-square-linkedin::before {
  content: '\0043';
}

.social-icon-square-pinterest::before {
  content: '\0044';
}

.social-icon-square-twitter::before {
  content: '\0045';
}

.social-icon-square-youtube::before {
  content: '\0046';
}

.social-icon-circle-facebook::before {
  content: '\0047';
}

.social-icon-circle-google-plus::before {
  content: '\0048';
}

.social-icon-circle-linkedin::before {
  content: '\0049';
}

.social-icon-circle-pinterest::before {
  content: '\004a';
}

.social-icon-circle-twitter::before {
  content: '\004b';
}

.social-icon-circle-youtube::before {
  content: '\004c';
}

.social-icon-rounded-facebook::before {
  content: '\004d';
}

.social-icon-rounded-google-plus::before {
  content: '\004e';
}

.social-icon-rounded-linkedin::before {
  content: '\004f';
}

.social-icon-rounded-pinterest::before {
  content: '\0050';
}

.social-icon-rounded-twitter::before {
  content: '\0051';
}

.social-icon-rounded-youtube::before {
  content: '\0052';
}

[class^="social-icon-circle-"]::before, [class*=" social-icon-circle-"]::before {
  border-radius: 100%;
}

[class^="social-icon-rounded-"]::before, [class*=" social-icon-rounded-"]::before {
  border-radius: 15%;
}

@font-face {
  font-family: 'chiro-icons';
  src: url("../fonts/chiro-icons.eot?s6wkck");
  src: url("../fonts/chiro-icons.eot?s6wkck#iefix") format("embedded-opentype"), url("../fonts/chiro-icons.ttf?s6wkck") format("truetype"), url("../fonts/chiro-icons.woff?s6wkck") format("woff"), url("../fonts/chiro-icons.svg?s6wkck#chiro-icons") format("svg");
  font-weight: normal;
  font-style: normal;
}

[class^="chiro-icon-"], [class*=" chiro-icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'chiro-icons' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/*=========================================================================*/
/* [Header Template Styles] */
.header {
  width: 100%;
  padding: 65px 0;
  position: relative;
  z-index: 10;
}

.header .h-logo img {
  width: 100%;
  margin-top: 10px;
}

.header .container {
  position: relative;
  z-index: 100;
}

.header-homepage {
  padding: 0;
}

.header-homepage .container-header {
  position: absolute !important;
  top: 20px;
  left: 50%;
  transform: translateX(-50%);
}

.header-subpage {
  padding: 40px 0;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
}

.header-subpage:before {
  content: ' ';
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 1;
  background: -moz-linear-gradient(0% 50% 0deg, white 0%, white 100%);
  background: -webkit-linear-gradient(0deg, white 0%, white 100%);
  background: -webkit-gradient(linear, 0% 50%, 100% 50%, color-stop(0, white), color-stop(1, white));
  background: -o-linear-gradient(0deg, white 0%, white 100%);
  background: -ms-linear-gradient(0deg, white 0%, white 100%);
  -ms-filter: "progid:DXImageTransform.Microsoft.gradient(startColorstr='#94C054', endColorstr='#33CDF4' ,GradientType=0)";
  background: linear-gradient(90deg, white 0%, white 100%);
  opacity: 0.8;
  filter: alpha(opacity=70) progid:DXImageTransform.Microsoft.Alpha(opacity=70) progid:DXImageTransform.Microsoft.gradient(startColorstr='#94C054',endColorstr='#33CDF4' , GradientType=1);
}

/* Portrait tablet to landscape and desktop */
@media (min-width: 768px) and (max-width: 991px) {
  .header .h-logo {
    text-align: center;
  }
  .header .h-logo img {
    width: 40%;
  }
}

/* Landscape phone to portrait tablet */
@media (max-width: 767px) {
  .header {
    padding: 35px 0;
  }
  .header-subpage {
    padding: 35px 0;
  }
  .header-homepage .container-header {
    position: relative !important;
    top: 0;
    padding-bottom: 20px;
  }
}

/*=========================================================================*/
/* [Navigation Template Styles] */
.navbar-default {
  min-height: 10px;
  float: right;
  margin: 40px 0 0 0;
  width: 100%;
  background-image: none;
  background-color: transparent;
  border: none;
  border-radius: 0;
  box-shadow: none;
  font-weight: 400;
}

.navbar-default .navbar-toggle {
  width: 100%;
  margin: 0;
  padding: 15px;
  background-color: #276FA5;
  border: none;
  font-size: 16px;
  color: white;
}

.navbar-default .navbar-toggle:hover, .navbar-default .navbar-toggle:focus {
  background-color: #276FA5;
}

.navbar-default .navbar-collapse {
  padding-left: 0;
  padding-right: 0;
}

.navbar-default .navbar-nav li a {
  padding: 5px 15px;
  font-size: 15px;
  font-weight: 400;
  color: #276FA5;
  text-shadow: none;
}

.navbar-default .navbar-nav li a:hover {
  color: #81ba4f;
}

.navbar-default .navbar-nav > li:first-child a {
  padding-left: 0;
}

.navbar-default .navbar-nav > li:last-child a {
  padding-right: 0;
}

.navbar-default .navbar-nav > .open > a,
.navbar-default .navbar-nav > .open > a:hover,
.navbar-default .navbar-nav > .open > a:focus {
  background-color: transparent;
  color: #81ba4f;
}

.navbar-default .navbar-nav > .active > a,
.navbar-default .navbar-nav > .active > a:hover,
.navbar-default .navbar-nav > .active > a:active,
.navbar-default .navbar-nav > .active > a:focus {
  background-color: transparent;
  color: #81ba4f;
}

.navbar-default .navbar-nav > .dropdown-menu {
  width: 100%;
}

.navbar-default .navbar-nav .dropdown-menu > .active > a,
.navbar-default .navbar-nav .dropdown-menu > .active > a:hover,
.navbar-default .navbar-nav .dropdown-menu > .active > a:focus {
  background: #81ba4f;
  color: white;
}

.navbar-default .navbar-nav > li > a.selector-centre {
  display: block;
  box-sizing: border-box;
  position: relative;
  background-color: transparent;
  color: #276FA5;
  font-weight: 400;
  font-size: 18px;
  line-height: 1.0em;
  text-align: center;
}

.navbar-default .navbar-nav > li > a.selector-centre:hover {
  background-color: #276FA5;
  color: white;
  text-decoration: none;
  cursor: pointer;
}

.navbar-default .navbar-nav > li > a.selector-centre small {
  font-size: 14px;
}

.navbar-default .navbar-collapse,
.navbar-default .navbar-form {
  border: none;
}

/* Large desktop */
@media (min-width: 992px) and (max-width: 1199px) {
  .navbar-default {
    margin: 48px 0 0 0;
  }
  .navbar-default .navbar-nav > li > a {
    padding: 5px 13px;
    font-size: 13px;
  }
  .navbar-default .navbar-nav > li > a.selector-centre {
    margin-right: 7px;
    padding: 8px 20px;
    border-radius: 5px;
    border: solid 1px rgba(39, 111, 165, 0.25);
  }
}

/* Portrait tablet to landscape and desktop */
@media (min-width: 768px) {
  .navbar-nav {
    float: right;
  }
}

/* Portrait tablet to landscape and desktop */
@media (min-width: 768px) and (max-width: 991px) {
  .navbar-default {
    margin-top: 20px;
    float: none;
    text-align: center;
  }
  .navbar-default .navbar-nav {
    display: inline-block;
    float: none;
  }
  .navbar-default .navbar-nav > li > a {
    padding: 5px 10px;
    font-size: 14px;
  }
  .navbar-default .navbar-nav > li > a.selector-centre {
    padding: 5px 8px;
    border-radius: 5px;
    border: solid 1px rgba(39, 111, 165, 0.25);
  }
}

/* Hover Click Fix */
@media (min-width: 768px) {
  .dropdown:hover .dropdown-menu {
    display: block;
    margin-top: 0;
  }
}

/* Landscape phone to portrait tablet */
@media (max-width: 767px) {
  .navbar-default {
    margin-top: 20px;
    float: none;
  }
  .navbar-default .navbar-nav {
    margin: 0;
    background-color: #f0f0f0;
  }
  .navbar-default .navbar-nav > li {
    border-bottom: 1px solid rgba(255, 255, 255, 0.3);
  }
  .navbar-default .navbar-nav > li > a {
    padding: 15px 0;
    font-size: 16px;
    color: #333333;
    text-align: center;
  }
  .navbar-default .navbar-nav > li > a:hover, .navbar-default .navbar-nav > li > a:focus, .navbar-default .navbar-nav > li > a:active {
    background-color: rgba(129, 186, 79, 0.2);
    color: #81ba4f;
  }
  .navbar-default .navbar-nav .open .dropdown-menu {
    background: #81ba4f;
  }
  .navbar-default .navbar-nav .open .dropdown-menu > li > a {
    padding: 15px 0;
    font-size: 16px;
    color: white;
    text-align: center;
  }
  .navbar-default .navbar-nav .open .dropdown-menu > li > a:hover, .navbar-default .navbar-nav .open .dropdown-menu > li > a:focus {
    background-color: rgba(39, 111, 165, 0.2);
    color: #81ba4f;
  }
}

/*=========================================================================*/
/* [Page Template Styles] */
.page {
  padding: 30px 0 0 0;
  min-height: 50vh;
}

.page.homepage {
  padding: 30px 0 0 0;
  min-height: auto !important;
}

.feature-navigation {
  width: 100%;
  z-index: 1;
  position: absolute;
  bottom: 60px;
}

.feature-navigation .feature-button {
  transition: all 200ms ease-in-out;
  border-radius: 10px;
}

.feature-navigation .feature-button:hover {
  background: #276FA5;
  color: white !important;
}

.feature-navigation .feature-button:hover .item span {
  color: white !important;
}

.feature-navigation .feature-button .item {
  display: block;
  width: 100%;
  padding: 15px 15px;
  position: relative;
  text-align: center;
  color: #276FA5;
  font-size: 30px;
  line-height: 1.0em;
}

.feature-navigation .feature-button .item:hover {
  text-decoration: none;
  color: white !important;
}

.feature-navigation .feature-button .item span {
  width: 136px;
  margin-bottom: 20px;
  color: #276FA5;
  font-size: 60px;
  transition: all 200ms ease-in-out;
}

.feature-navigation .feature-button .item small {
  font-size: 18px;
}

.download {
  width: 100%;
  margin: 0 0 20px 0;
  float: left;
}

.download .icon {
  width: 100%;
  float: left;
  height: 120px;
  background-image: url(../images/ebook-icon.jpg);
  background-repeat: no-repeat;
  background-size: cover;
}

.download .details {
  width: 100%;
  padding: 15px;
  height: 100%;
  float: left;
  background-color: #81ba4f;
  color: white;
  text-align: center;
}

.download .details h3 {
  margin: 0 0 5px;
  padding: 0;
  color: white;
  line-height: 1.0em;
  font-size: 16px;
  font-weight: 700;
}

.download .details a.btn {
  margin: 8px 0 0 0;
  background-color: #276FA5;
  color: white;
}

.download .details a.btn:hover {
  background-color: #436327;
  color: white;
}

.infosheets {
  padding: 15px;
  width: 100%;
  float: left;
  background-color: rgba(175, 175, 175, 0.2);
}

.infosheets h3 {
  margin: 0;
  padding: 0;
  color: #6d6e71;
  text-align: center;
  font-weight: 700;
  font-size: 18px;
}

.infosheets ul {
  margin: 15px 0 0 0;
  padding: 0;
  list-style-type: none;
}

.infosheets ul > li {
  margin: 2px 0;
  border: 1px solid rgba(0, 0, 0, 0.1);
}

.infosheets ul > li > a {
  display: block;
  padding: 8px 15px 7px 55px;
  background-image: url(../images/Adobe_PDF_file_icon_24x24.png);
  background-repeat: no-repeat;
  background-position: 15px center;
  color: #6d6e71;
}

.infosheets ul > li > a:hover {
  text-decoration: none;
  background-color: white;
}

/* Large desktop */
@media (min-width: 1200px) {
  .download .details h3 {
    font-size: 20px;
  }
}

/* Portrait tablet to landscape and desktop */
@media (min-width: 768px) and (max-width: 991px) {
  .feature-navigation {
    position: relative;
    padding: 20px 0;
    bottom: auto;
  }
  .feature-navigation .item {
    font-size: 24px;
    line-height: 1.0em;
  }
  .feature-navigation .item img {
    width: 90px;
    margin-bottom: 10px;
  }
  .feature-navigation .item small {
    font-size: 14px;
  }
  .download {
    height: auto;
  }
  .download .icon {
    width: 100%;
    height: 100px;
  }
  .download .details {
    width: 100%;
    height: auto;
    text-align: center;
  }
  .download h3 {
    font-size: 17px;
    font-weight: 700;
  }
}

/* Landscape phone to portrait tablet */
@media (max-width: 767px) {
  .feature-navigation {
    position: relative;
    padding: 20px 0;
    bottom: auto;
  }
  .feature-navigation .item {
    font-size: 24px;
    line-height: 1.0em;
  }
  .feature-navigation .item img {
    width: 90px;
    margin-bottom: 10px;
  }
  .feature-navigation .item small {
    font-size: 14px;
  }
}

/*=========================================================================*/
/* [Footer Template Styles] */
.footer {
  width: 100%;
  padding: 40px 0;
  float: left;
  margin-top: 40px;
  background-color: #62bb46;
  color: rgba(255, 255, 255, 0.7);
  font-size: 12px;
}

.footer h5 {
  margin-top: 0;
}

.footer a {
  color: rgba(255, 255, 255, 0.7);
}

.footer a:hover {
  color: white;
  text-decoration: none;
}

.footer a .btn {
  margin: 0 0 30px 0;
  background-color: rgba(0, 0, 0, 0.4);
  color: white;
}

.footer a .btn:hover {
  background-color: white;
  color: rgba(0, 0, 0, 0.8);
}

.footer ul {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

.footer ul > li {
  padding: 5px 0;
  border-top: 1px solid rgba(255, 255, 255, 0.7);
}

.footer .row-social {
  margin-bottom: 40px;
  font-size: 30px;
  text-align: right;
}

.footer .row-social a {
  color: rgba(255, 255, 255, 0.7);
}

.footer .row-social a:hover {
  color: white;
}

.logo-chiropractic-iq {
  transition: all 150ms ease-in-out;
  width: 100%;
  opacity: 0.5;
}

.logo-chiropractic-iq:hover {
  opacity: 1;
}

.logo-caa {
  width: 100%;
}

/* Landscape phone to portrait tablet */
@media (max-width: 767px) {
  .footer {
    text-align: center;
  }
  .footer h5 {
    margin: 30px 0 10px 0;
  }
  .logo-chiropractic-iq {
    margin-top: 30px;
    width: 50%;
  }
  .logo-caa {
    width: 20%;
  }
}

/*=========================================================================*/
/* [Bootstrap Row Clear Fix Styles] */
#media-query-detector {
  display: none;
  width: 0px;
}

@media (min-width: 768px) {
  #media-query-detector {
    width: 768px;
  }
}

@media (min-width: 992px) {
  #media-query-detector {
    width: 992px;
  }
}

@media (min-width: 1200px) {
  #media-query-detector {
    width: 1200px;
  }
}

.row-fix-clear-both {
  clear: both;
}

/*=========================================================================*/
/* [Table as row override Styles] */
table.table-as-row > tbody > tr:before,
table.table-as-row > tbody > tr:after {
  content: " ";
  display: table;
  clear: both;
}

table.table-as-row > tbody > tr {
  height: auto !important;
}

table.table-as-row,
table.table-as-row > tbody,
table.table-as-row > tbody > tr {
  display: block;
  width: auto;
}

table.table-as-row > tbody > tr {
  display: block;
  width: auto;
  margin-right: -15px;
  margin-left: -15px;
}

table.table-as-row > tbody > tr > td[class*=col-] {
  display: block;
  height: auto !important;
  margin-bottom: 20px;
}

/* col-lg */
@media (min-width: 1200px) {
  table.table-as-row > tbody > tr > td[class*=col-lg-] {
    float: left;
  }
}

/* col-md */
@media (min-width: 992px) {
  table.table-as-row > tbody > tr > td[class*=col-md-] {
    float: left;
  }
}

/* col-sm */
@media (min-width: 768px) {
  table.table-as-row > tbody > tr > td[class*=col-sm-] {
    float: left;
  }
}

/* col-xs */
table.table-as-row > tbody > tr > td[class*=col-xs-] {
  float: left;
}

/*=========================================================================*/
/* [Slick Slider Styles] */
.slider-overlay {
  position: absolute;
  z-index: 1000;
  width: 100%;
  top: 50%;
  transform: translateY(-100%);
  color: #276FA5;
  font-size: 50px;
  text-align: center;
  font-weight: 700;
}

.slider-overlay small {
  color: #81ba4f;
  font-weight: 300;
}

.slider-wrapper {
  width: 100%;
  position: relative;
}

.slick-slide::before {
  content: ' ';
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 1;
  background: -moz-linear-gradient(0% 50% 0deg, white 0%, white 100%);
  background: -webkit-linear-gradient(0deg, white 0%, white 100%);
  background: -webkit-gradient(linear, 0% 50%, 100% 50%, color-stop(0, white), color-stop(1, white));
  background: -o-linear-gradient(0deg, white 0%, white 100%);
  background: -ms-linear-gradient(0deg, white 0%, white 100%);
  -ms-filter: "progid:DXImageTransform.Microsoft.gradient(startColorstr='#94C054', endColorstr='#33CDF4' ,GradientType=0)";
  background: linear-gradient(90deg, white 0%, white 100%);
  opacity: 0.66;
  filter: alpha(opacity=66) progid:DXImageTransform.Microsoft.Alpha(opacity=66) progid:DXImageTransform.Microsoft.gradient(startColorstr='#94C054',endColorstr='#33CDF4' , GradientType=1);
}

.slickSlider img {
  display: none;
}

.slickSlider.slick-initialized img {
  display: block;
}

.slickSlider .slick-prev {
  left: 10px;
  z-index: 100;
  opacity: 0;
}

.slickSlider .slick-next {
  right: 10px;
  z-index: 100;
  opacity: 0;
}

.slickSlider:hover .slick-prev,
.slickSlider:hover .slick-next {
  opacity: 0.75;
}

/* Desktop */
@media (min-width: 992px) and (max-width: 1199px) {
  .slider-overlay {
    font-size: 30px;
  }
}

/* Landscape phone to portrait tablet */
@media (max-width: 767px) {
  .slick-dots {
    display: none !important;
  }
}

.container-book {
  position: relative;
}

.book-btn-header {
  position: absolute;
  z-index: 1000;
  top: -10px;
  right: 0;
}

.container-book a.btn-book-header {
  border: solid 1px #81ba4f;
  color: white;
  background: #81ba4f;
  border-radius: 0;
  padding: 8px 40px;
  font-size: 14px;
}

.container-book a.btn-book-header:hover,
.container-book a.btn-book-header:active,
.container-book a.btn-book-header:focus {
  border: solid 1px #81ba4f;
  background: #276FA5;
  color: white;
}

@media (min-width: 768px) and (max-width: 991px) {
  .book-btn-header {
    position: relative;
    width: 100%;
    top: 200px;
  }
  .book-btn-header a {
    display: block;
  }
}

@media (max-width: 767px) {
  .book-btn-header {
    position: relative;
    width: 100%;
    margin-top: 12px;
    top: 0;
  }
  .book-btn-header a {
    display: block;
  }
}

.book-btn {
  position: relative;
  display: block;
  width: 100%;
  text-align: center;
  margin: 10px 0;
}

.book-btn a.btn-book {
  border: solid 1px #81ba4f;
  color: #81ba4f;
  display: block;
  text-align: center;
  border-radius: 0;
  padding: 8px 16px;
  font-size: 14px;
  margin-bottom: 10px;
}

.book-btn a.btn-book:hover,
.book-btn a.btn-book:active,
.book-btn a.btn-book:focus {
  border: solid 1px #81ba4f;
  background: #276FA5;
  color: white;
}

.btn-success {
  background-color: #276FA5;
  border-color: #184567;
}

.btn-success:hover {
  color: #fff;
  background-color: #4596d3;
  border-color: #276FA5;
}
